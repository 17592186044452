import React, { useRef, useState } from 'react'

import './menulogin.css'
import { Row, Col } from 'react-bootstrap'
import logo from './images/gkjLogoBiru.png'
import glLogo from './images/Logo GL-removebg.png'
import ppLogo from './images/Logo PP-removebg.png'
import dwLogo from './images/Logo DW-removebg.png'
import agLogo from './images/Logo AG-removebg.png'
import iaLogo from './images/Logo IA-removebg.png'

const totalImageBackground = 12
const num = Math.ceil( Math.random() * totalImageBackground )
const bgImg = 'bg-wp'+num

const MenuLogin = () => {
	const [aktif, setAktif] = useState(false)
	const [aktifOps, setAktifOps] = useState(0)

	const dataLink = [
		{ id: 0, name: "home", icon: "house-fill text-primary", url: ''},
		{ id: 1, name: "GL", icon: glLogo, url: 'https://gl.gkj.web.id/'},
		{ id: 2, name: "PP", icon: ppLogo, url: 'https://pp.gkj.web.id/'},
		{ id: 3, name: "DW", icon: dwLogo, url: 'https://dw.gkj.web.id/'},
		{ id: 4, name: "home", icon: "house-fill text-success", url: ''},
		{ id: 5, name: "IA", icon: iaLogo, url: 'https://ia.gkj.web.id/'},
		{ id: 6, name: "AG", icon: agLogo, url: 'https://ag.gkj.web.id/'},
		{ id: 7, name: "SP", icon: "safe-fill text-warning", url: 'https://sp.gkj.web.id/'}
	]
	
	const clickToggle = () => { setAktif((prev) => !prev) }
	const clickOps = (e) => { 
		setAktifOps(e.id) 
		if (e.name === "GL") {
			setTimeout(() => {
				window.location.replace(e.url)
			}, 750)
		}
		if (e.name === "PP") {
			setTimeout(() => {
				window.location.replace(e.url)
			}, 750)
		}
		if (e.name === "DW") {
			setTimeout(() => {
				window.location.replace(e.url)
			}, 750)
		}
		if (e.name === "IA") {
			setTimeout(() => {
				window.location.replace(e.url)
			}, 750)
		}
		if (e.name === "AG") {
			setTimeout(() => {
				window.location.replace(e.url)
			}, 750)
		}
		if (e.name === "SP") {
			setTimeout(() => {
				window.location.replace(e.url)
			}, 750)
		}
	}
	
	const aktifLink = useRef(Array(dataLink).fill(React.createRef()))

	return (
		<div className={bgImg.concat(' bg-container')}>
			<div className='w-100 text-center mt-1'>
				<img src={logo} width="5%" className='rounded-4 border border-2 border-info opacity-75' alt='Logo GKJ' />
			</div>
			<h2 className='text-primary fw-bold text-center mt-1'> Selamat Datang di Website GKJ - Apps </h2>
			<Row className='w-100'>
				<Col className='col-1 pe-0'></Col>
				<Col className='col-10 justify-content-center mt-3 ps-0'>
					<ul className={ `w-100 text-center menu ${aktif ? ' active' : ''}` } ref={aktifLink}>
						<div className={ `toggle ${aktif ? ' active' : ''}` }>
							<i className="bi bi-plus-lg text-warning text-center" onClick={clickToggle} ></i>
						</div>
						{dataLink.map((item, i) => (
							<li key={item.id} style={{ "--i": [i] }} onClick={() => clickOps(item)} className={ `text-center li ${item.id === aktifOps ? ' active' : ''}` }>
								<a href={'#'.concat(item.url)}> { 
									(item.id === 0 || item.id === 4 || item.id === 7)
										? <i className={"bi bi-".concat(item.icon)} ></i>
										: <img src={item.icon} width="100%" className='fw-bold' alt='icon menu' />
								} </a>
							</li>
						))}
						<div className='indicator'></div>
					</ul>
				</Col>
				<Col className='col-1'></Col>
			</Row>
		</div>
	)
}

export default MenuLogin