import React from "react"

import { Routes, Route } from "react-router-dom"
import MenuLogin from "./MenuLogin"

function App() {

  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<MenuLogin />} />
      </Routes>
    </div>
  )
}

export default App