import React from 'react'
import ReactDOM from 'react-dom'
// eslint-disable-next-line
import bootstrap from 'bootstrap'
import 'bootstrap/scss/bootstrap.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './css/custom.min.css'
import App from './App'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

document.title = 'GKJ-Apps'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
